form .MuiInputBase-input,
form .textAreaControl {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Normal;
}
form .MuiInputBase-input::-moz-placeholder,
form .textAreaControl::-moz-placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Normal;
}
form .MuiInputBase-input::placeholder,
form .textAreaControl::placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Normal;
}

form .MuiInputBase-input::-ms-input-placeholder,
form .textAreaControl::-ms-input-placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Normal;
}
