form .MuiInputBase-input,
form .textAreaControl {
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: Normal;
  font-weight: 400;
}
form .MuiInputBase-input::-moz-placeholder,
form .textAreaControl::-moz-placeholder {
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: Normal;
  font-weight: 400;
}
form .MuiInputBase-input::placeholder,
form .textAreaControl::placeholder {
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: Normal;
  font-weight: 400;
}

form .MuiInputBase-input::-ms-input-placeholder,
form .textAreaControl::-ms-input-placeholder {
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: Normal;
  font-weight: 400;
}
