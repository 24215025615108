@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

.card-bg-grad {
  /* background: linear-gradient(180deg, #dcf5bd 42.78%, #6ec0b2 142.66%); */
  background: linear-gradient(180deg, rgba(220, 245, 189, 0.3) 42.78%, rgba(110, 192, 178, 0.5) 142.66%);
  /* background: linear-gradient(
    180deg,
    rgba(243, 245, 230, 0.5) 0%,
    rgba(247, 234, 223, 0.5) 30.02%,
    rgba(66, 183, 194, 0.5) 130.52%
  ); */
}

/* .card-bg-grad * {
    color: #ffffff !important;
} */

/* Custom scroll css */
.mostly-customized-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  background: #9fbb7e;
  border-radius: 8px;
}

.card-bg-grad * {
  font-family: 'Montserrat', sans-serif !important;
}

.card-bg-grad .baskerville,
.card-bg-grad .baskerville * {
  font-family: 'Libre Baskerville', serif !important;
}
